<template>
  <div class="download" :style="{backgroundImage: 'url(' + download_url + ')'}">
    <!-- <div class="title" style="float: left; margin-left: 0.2rem">
      <span>
        <van-icon
          @click="backToIndex"
          color="#D9D8E7"
          class="titlePStyle"
          name="arrow"
        />
      </span>
    </div> -->
	<!-- <div class="headIcon"><img src="@/assets/imgs/login/rainbow.png" alt="" /></div> -->
    <div class="shareFooter">
      <div>
        <a class="iosButton" :href="iosurl">
          <img src="../../assets/imgs/icon/ios.png" />
          IOS下载
        </a>
      </div>
      <div>
        <a class="androidButton" :href="url">
          <img src="../../assets/imgs/icon/android.png" />
          安卓下载
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      refcode: this.$route.query.refcode,
      url: "",
      iosurl: "",
	  download_url: ''
    };
  },
  created() {
    this.getConfig();
	this.getPictureList();
  },
  methods: {
    backToIndex() {
      this.$router.push("/");
    },
    getConfig() {
      this.$post2("/wallet/api/index/version")
        .then((res) => {
          this.url = res.apk.url;
          console.log(this.url);
        })
        .catch((e) => {});
    },
	//获取背景图
	getPictureList() {
		this.$post2('User/Api/Passport/getPictureList', {})
			.then(res => {
				this.download_url = res.download_url;
			})
			.catch(e => {
				// this.$toast.fail(e);
			});
	}
  },
};
</script>

<style lang="less" scoped>
.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
.headIcon {
			img {
				width: 2.4rem;
				height: 2.4rem;
				display: block;
				margin: 0 auto;
			}
		}
.download {
  // background-image: url("../../assets/imgs/share/backGroundImg2.png");
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;

  .shareFooter {
    position: absolute;
    bottom: 1.45rem;
    left: 0;
    width: 100%;

    div {
      width: 50%;
      float: left;

      a {
        display: block;
        margin: auto;
        width: 2.54rem;
		height: 0.8rem;
        border-radius: 5px;
        line-height: 0.8rem;
        font-size: 0.3rem;
      }

      a.iosButton {
        color: #0E39DE;
		font-weight: 600;
        background: #fff;
		border-radius: 0.16rem;
      }

      a.androidButton {
        color: #0E39DE;
		font-weight: 600;
        background: #fff;
		border-radius: 0.16rem;
      }

      img {
        width: 0.4rem;
        vertical-align: -0.1rem;
      }
    }
  }
}
</style>
